<section class="banner-section">
    <div class="banner-info">
        <div class="person-img">
            <img src="./assets/images/nurse-consultants/imgs/Person.png" alt="person">
        </div>
        <div class="investigator-info">
            <h2 class="heading">
                Personal Investigators
            </h2>
            <div class="info">
                Personal investigators can enhance their investigative prowess with TrialLine by seamlessly
                organizing
                case details, analyzing timelines to uncover critical insights, collaborating effectively with
                clients
                and legal teams, and presenting compelling visual narratives, ultimately amplifying their ability to
                uncover truth and deliver results efficiently.

            </div>
            <i class="bottom-info">Maximize Your Case Impact With TrailLine</i>
        </div>
    </div>
    <div class="button-wrapper">
        <a href="/#contact">
            <button>Start Your Free Trial Today</button>
        </a>
    </div>
    <div class="bg-img">
    </div>
</section>
<section class="services-section">
    <div class="container">
        <div class="sevices-content-wrapper">
            <div class="left-info-content">
                <h2>Organize effortlessly,
                    collaborate seamlessly
                    and gather strategic
                    insights.</h2>
                <div class="blue-line"></div>
                <p>Generate comprehensive timelines that summarize key events and findings.</p>
            </div>
            <div class="cards-wrapper">
                <div class="card">
                    <img src="./assets/images/nurse-consultants/icons/sitting-person-icon.svg" alt="sitting person icon">
                    <h3>Map Out Your Investigations</h3>
                    <ul>
                        <li>Chornologically input and organize key events in your investigation into TrailLine.
                        </li>
                        <li>Store and access all case documents in a single, cloud-based location. TrialLine is
                            accessible
                            from anywhere, anytime. </li>
                    </ul>
                </div>
                <div class="card">
                    <img src="./assets/images/nurse-consultants/icons/group-discussion.svg" alt="group discussion">
                    <h3>Collaborate with Colleagues</h3>
                    <ul>
                        <li>Work seamlessly with clients, legal teams, and fellow investigators from any location.
                        </li>
                        <li>Share your TrialLine access, update, and access timelines remotely for uninterrupted progress.</li>
                    </ul>
                </div>
                <div class="card">
                    <img src="./assets/images/nurse-consultants/icons/bulb-icon.svg" alt="idea icon">
                    <h3>Discover and Analyze</h3>
                    <ul>
                        <li>Identify patterns and connections to strengthen your investigative strategy.
                        </li>
                        <li>Annotate timelines with detailed notes and highlight crucial events.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="demo-banner">
            <p>Elevate Your Investigations with TrialLine. The Essential Tool for Powerful, Effective Timeline
                Creation and Presentation</p>
            <a href="https://calendly.com/triallinedemo/triallinedemo?month=2024-10">
                <button class="book-demo-btn">Book a Demo</button>
            </a>
        </div>
    </div>
</section>