import {ChangeDetectorRef, Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {UserProfile} from "../shared/user.model";
import {ProfileResourceService} from "../shared/profile-resoruce.service";
import {AuthService} from "../../../auth/shared/auth.service";
import {Modal} from 'ngx-modialog-7/plugins/bootstrap';
import {CouponResourceService} from "../shared/coupon-resoruce.service";
import { config } from 'process';
import { CookieService } from 'ngx-cookie';
import { count } from 'console';
import { forEach } from 'jszip';
// import {Overlay} from 'ngx-modialog';
import flow from "@prosperstack/flow";

declare var Stripe: any;
declare var jQuery: any;

@Component({
  selector: 'app-profile-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class ProfileSubscriptionComponent {
  user: any;
  plan: string;
  cardModalId = '#add-card-modal';

  renewing: boolean = false;
  newTrialUser: boolean = false;
  monthlyFreeTrialCoupon: any;
  yearlyFreeTrialCoupon: any;
  oldtonew: boolean ;
  oldtonew1: boolean ;
  subscription_amount: string;
  subscription_plan: string;
  january22User : boolean;
  isCouponInputVisibleMonth: boolean = false;
  isCouponInputVisibleyear: boolean = false;
  couponCodeyear: string;
  couponCodeMonth: string;
  discountedPrice: number = 0;
  finalAmout: number = 0;
  couponApplied: boolean = false;
  isCouponApplied: boolean = false;
  isCouponInputValid = false;
  closeCouponInputAfterClick: boolean = true;
  couponObj = {
    planId: '',
    planPrice: 0,
    discountedAmount: 0,
    finalAmount: 0,
    active: false
  };
  
  public freeCouponForTrialUsers = {
      month: {
        couponName: '',
        planID : '',
        planPrice: 0,
        discountedAmount: 0,
        finalAmount: 0,
        tempDisplayAmount: 0
      },
      year: {
        couponName: '',
        planID : '',
        planPrice: 0,
        discountedAmount: 0,
        finalAmount: 0,
        tempDisplayAmount: 0
      }
  };

  public monthlyDiscountCoupon = {
    couponName: 'dollar-10-discount'
  };

  public yearlyDiscountCoupon = {
    couponName: 'dollar-103-discount'
  };
  
  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute,
    protected router: Router,
    protected auth: AuthService,
    private profileResourceService: ProfileResourceService,
    private couponResourceService: CouponResourceService,
    protected _changeDetectionRef: ChangeDetectorRef,
    private modal: Modal,
    public cookieService: CookieService, 
  ) {
    title.setTitle('TrialLine | Subscription')
  }

  async ngOnInit() {
    
    this.route.data.subscribe((data: { user: UserProfile }) => {

      this.user = data.user;
      // this.user.subscription_plan!='month_59_per_month' || this.user.subscription_plan!='annual_499_per_year'
      if((this.user.subscription_plan!='month') &&( this.user.subscription_plan!='annual') && (this.user.subscription_plan!=null) && (this.user.subscription_plan!='annual_499_per_year') && (this.user.subscription_plan!='month_59_per_month'))
      {
        this.oldtonew=false
        this.oldtonew1=false;
      }else
      {
        this.oldtonew=true
        this.oldtonew1=false;
      }
      // new pricing update january 22
      let pricingUpdateDate = new Date('January 5, 2022 00:00:00');
      let userCreatedAtDate = new Date(this.user.created_at);

      if (userCreatedAtDate >=  pricingUpdateDate){
        this.january22User = true;
      }else {
        this.january22User = false;
      }
      
    });

    // Get Subscription Discounts and put it into the veriables -- daynamic
    await this.couponResourceService.getSubscriptionDiscounts().toPromise()
    .then((response: any) => {
      
      if(response.length > 0) {
        response.forEach(obj => {
          if(obj.subscription == 'month-to-month') {
            this.monthlyDiscountCoupon.couponName = obj.coupon_name
          }
          if(obj.subscription == 'yearly') {
            this.yearlyDiscountCoupon.couponName = obj.coupon_name
          }
        });
      } else {
        this.monthlyDiscountCoupon.couponName = '';
        this.yearlyDiscountCoupon.couponName = '';
      }
    })
    .catch((error) => {
      console.log(error.err);        
    });


    //Statically add discount coupons
    await this.profileResourceService.freeTrialUser().toPromise()
      .then((res: any) => {
        if(res) {
          console.log('res:',res)
          this.newTrialUser = true;
          this.cookieService.put('showFreeTrialPopup', 'downtoup');
          
          if(this.monthlyDiscountCoupon.couponName != '') {
            this.freeUserCouponCode(this.monthlyDiscountCoupon.couponName, 59);  
          } else {
            this.deleteObjectMembers('freeCouponForTrialUsers', 'month');
          }
          if(this.yearlyDiscountCoupon.couponName != '') {
            this.freeUserCouponCode(this.yearlyDiscountCoupon.couponName, 499);
          } else {
            this.deleteObjectMembers('freeCouponForTrialUsers', 'year');
          }

        } else {
          this.deleteObjectMembers('freeCouponForTrialUsers', 'month');
          this.deleteObjectMembers('freeCouponForTrialUsers', 'year');
          this.newTrialUser = false;
          this.cookieService.remove('showFreeTrialPopup');
        }
      })
      .catch((error) => {
        console.log('free Trial User Error: ', error);        
    });
      
  }

  deleteObjectMembers(dataObj, pram) {
    switch (dataObj) {
      case "freeCouponForTrialUsers":
        if(pram == 'month') {
          this.freeCouponForTrialUsers.month.couponName = '';
          this.freeCouponForTrialUsers.month.planID = '';
          this.freeCouponForTrialUsers.month.planPrice = 0;
          this.freeCouponForTrialUsers.month.discountedAmount = 0;
          this.freeCouponForTrialUsers.month.finalAmount = 0;
        } else if(pram == 'year') {
          this.freeCouponForTrialUsers.year.couponName = '';
          this.freeCouponForTrialUsers.year.planID = '';
          this.freeCouponForTrialUsers.year.planPrice = 0;
          this.freeCouponForTrialUsers.year.discountedAmount = 0;
          this.freeCouponForTrialUsers.year.finalAmount = 0;
        }
      break;
    }
  }
  ngOnDestroy() {
    this._changeDetectionRef.detach();
  }

  checkCouponInput(type: 'year' | 'month') {
    if (type === 'year') {
      this.isCouponInputValid = !!this.user.couponCodeyear;
    } else if (type === 'month') {
      this.isCouponInputValid = !!this.user.couponCodeMonth;
    }
  }

  toggleCouponInput(type: 'year' | 'month') {
    if (type === 'year') {
      this.isCouponInputVisibleyear = !this.isCouponInputVisibleyear;
      this.isCouponInputVisibleMonth = false;
      this.closeCouponInputAfterClick = false;
    } else if (type === 'month') {
      this.isCouponInputVisibleMonth = !this.isCouponInputVisibleMonth;
      this.isCouponInputVisibleyear = false;
      this.closeCouponInputAfterClick = false;
    }
  }

  closeCouponInput() {
    if (this.closeCouponInputAfterClick) {
      this.isCouponInputVisibleyear = false;
      this.isCouponInputVisibleMonth = false;
      this.closeCouponInputAfterClick = false;
    } else {
      this.closeCouponInputAfterClick = true;
    }
  }

  createSubscription(planId) { 
    let couponCodeToSend: string;
    
    // Coupon Code applied by customer
    if (this.isCouponInputVisibleyear) {
      couponCodeToSend = this.user.couponCodeyear;
    } else if (this.isCouponInputVisibleMonth) {
      couponCodeToSend = this.user.couponCodeMonth;
    } else {
      couponCodeToSend = '';
    }

    //Free Coupon for Trial Users
    if (this.newTrialUser) {
      console.log('this.freeCouponForTrialUsers:- ', this.freeCouponForTrialUsers);
      if(planId == 'annual_499_per_year') {
        couponCodeToSend = this.freeCouponForTrialUsers.year.couponName;
      } else if(planId == 'month_59_per_month') {
        couponCodeToSend = this.freeCouponForTrialUsers.month.couponName;
      }
    }
    

    const payload = {
      planId: planId,
      couponCode: couponCodeToSend
    };
    
    this.profileResourceService.createSubscription(payload).toPromise()
      .then((r: any) => {
        this.user.subscription_plan = planId;
        this.toasts.success('You successfully subscribed to plan: ' + planId);
        this.auth.emit();
        this.user.couponCodeMonth = '';
        this.user.couponCodeyear = '';
        this._changeDetectionRef.detectChanges();
        this.isCouponInputVisibleMonth = false;
        this.isCouponInputVisibleyear = false;
        this.newTrialUser = false;
        this.cookieService.remove('showFreeTrialPopup');
      })
      .catch((error) => {
        let context = error;
        if (context.error.message) {
          this.toasts.error(context.error.message);
        }
      });
  }

  renewSubscription() {

    if (!this.renewing) {

      this.renewing = true;
      this.profileResourceService.renewSubscription().toPromise()
        .then((user: any) => {
          this.renewing = false;
          this.user = user;
          this.auth.emit();
        }).catch(error => {
        this.renewing = false;
        let errorData = error.error;
        if (errorData.error.message) {
          this.toasts.error(errorData.error.message); 
        } else {
          this.toasts.error(error);
        }
      })
    }
  }

  couponUpdatePlanId(planPrice) {
    switch(planPrice) {
      case 499:
        return 'annual_499_per_year'
      break;
      case 59:
        return 'month_59_per_month'
      break;
    }
  }

  freeUserCouponCode(couponvalue, planPrice) {
    
    this.couponResourceService.getCouponByName(couponvalue).toPromise()
      .then((response: any) => {
        console.log('Coupon by Name Res:',response)
        if(planPrice == 59) {
          // alert('here')
          // console.log('testing 11');
          this.freeCouponForTrialUsers.month.couponName = couponvalue;
          this.freeCouponForTrialUsers.month.planID = this.couponUpdatePlanId(planPrice);
          this.freeCouponForTrialUsers.month.planPrice = planPrice;
          if(response.body.discount_type == 'percent_off') {
            this.freeCouponForTrialUsers.month.discountedAmount =  planPrice * response.body.coupon.percent_off / 100;
            this.freeCouponForTrialUsers.month.finalAmount = planPrice - this.freeCouponForTrialUsers.month.discountedAmount;
          } else {
            this.freeCouponForTrialUsers.month.discountedAmount =  response.body.coupon.amount_off;
            this.freeCouponForTrialUsers.month.finalAmount = planPrice - this.freeCouponForTrialUsers.month.discountedAmount;
          }
          
        } else if(planPrice == 499) {
          this.freeCouponForTrialUsers.year.couponName = couponvalue;
          this.freeCouponForTrialUsers.year.planID = this.couponUpdatePlanId(planPrice);
          this.freeCouponForTrialUsers.year.planPrice = planPrice;
          if(response.body.discount_type == 'percent_off') {
            this.freeCouponForTrialUsers.year.discountedAmount =  planPrice * response.body.coupon.percent_off / 100;
            this.freeCouponForTrialUsers.year.finalAmount = planPrice - this.freeCouponForTrialUsers.year.discountedAmount;
            this.freeCouponForTrialUsers.year.tempDisplayAmount = 0; // under construction
          } else {
            this.freeCouponForTrialUsers.year.discountedAmount =  response.body.coupon.amount_off;
            this.freeCouponForTrialUsers.year.finalAmount = planPrice - this.freeCouponForTrialUsers.year.discountedAmount;
            this.freeCouponForTrialUsers.year.tempDisplayAmount = this.freeCouponForTrialUsers.year.finalAmount / 12; 
          }
        }

      })
      .catch((error) => {
          console.log('error: ', error);
      });

  }

  checkCouponCode(couponvalue, planPrice) {
    if (!couponvalue || couponvalue.trim() === '') {
      this.toasts.error('Coupon code should not be empty');
      return;
    }
  
    this.couponResourceService.getCouponByName(couponvalue).toPromise()
      .then((response: any) => {
        this.couponObj.planId = this.couponUpdatePlanId(planPrice);
        this.couponObj.planPrice = planPrice;
        this.couponObj.discountedAmount = planPrice * response.body.coupon.percent_off / 100;
        this.couponObj.finalAmount = planPrice - this.couponObj.discountedAmount;
        this.couponObj.active = true;
        this.toasts.success(response.body.success);
        this.isCouponApplied = true;
      })
      .catch(err => {
        this.toasts.error(err.error.error);
      });
  }

  isMonthCouponApplied(): boolean {
    return this.user.couponCodeMonth && this.user.couponCodeMonth.trim() !== '';
  }

  isYearCouponApplied(): boolean {
    return this.user.couponCodeyear && this.user.couponCodeyear.trim() !== '';
  }


  removeCoupon() {
    this.couponObj = {
      planId: '',
      planPrice: 0,
      discountedAmount: 0,
      finalAmount: 0,
      active: false
    };
    this.isCouponApplied = false;
    this.isCouponInputValid = false;
    this.user.couponCodeMonth = '';
    this.user.couponCodeyear = '';
    this.toasts.success('Coupon code remove successfully');
  }
  
  tryToSubscribe(planId)
  {
    // debugger;
    if (planId != this.user.subscription_plan) {
      if (this.user.subscription_plan == 'medium' && planId == 'low') {
        return this.toasts.error('You can not subscribe on lowest plan');
      } else if (this.user.subscription_plan == 'high' && (planId == 'low' || planId == 'medium')) {
        return this.toasts.error('You can not subscribe on lowest plan');
      }
      this.plan = planId;

      if (this.user.card_last_four) {
         let body = '';
         let title = '';
         let className = '';
        if (this.user.trial_days_count) {
          body = 'Once you have updated your subscription level your trial period will be cancelled.';
          title = 'Are you sure?';
          className = 'confirm-dialog';
        } else {
          let self = this;
          this.getSubscriptionAmount(planId);
          body = "You will be subscribed to the <b>" + self.subscription_plan + "</b> plan. You will be billed <b>" + self.subscription_amount + "</b>.";
          title = 'Please, confirm your subscription';
          className = 'confirm-in'
        }
         const dialogRef = this.modal.confirm()
           .size('lg')
           .isBlocking(true)
           .showClose(true)
           .keyboard(27)
           .dialogClass(className)
           .title(title)
           .body(body)
           .open();
         dialogRef.result
           .catch((err: any) => {console.log('ERROR: ' + err)
             this.modelClose()
           })
           .then((dialog: any) => {
             this.modelClose()
             if (dialog) {
               this.createSubscription(planId)
             }
           })
           .catch((err: any) => { /** Nothing to do */
           });
         this.modelAction()
      } else {
        this.openCardModal();
      }
    }
  }



  getSubscriptionAmount(planId) {
    switch (planId) {
      case 'month_59_per_month':
        if(this.couponObj.planId == 'month_59_per_month' && this.couponObj.active === true) {
          this.subscription_amount = "<s>$59</s> " + this.couponObj.finalAmount + " per month";
          this.subscription_plan = "Month-to-Month";
        } else {
          this.subscription_amount = "$59 per month";
          this.subscription_plan = "Month-to-Month";
        }
        
        break;
      case 'month_59_per_month_team':
        this.subscription_amount = "$59 per month";
        this.subscription_plan = "Month-to-Month";
        break;
      case 'annual_499_per_year':
        if(this.couponObj.planId == 'annual_499_per_year' && this.couponObj.active === true) {
          this.subscription_amount = "<s>$499</s> " + this.couponObj.finalAmount + " per year";
          this.subscription_plan = "Annual";
        } else {
          this.subscription_amount = "$499 per year";
        this.subscription_plan = "Annual";
        }        
        break;
      case 'annual_499_per_year_team':
        this.subscription_amount = "$499 per year";
        this.subscription_plan = "Annual";
        break;
      case 'month':
        this.subscription_amount = "$49 per month";
        this.subscription_plan = "Month-to-Month";
        break;
      case 'annual':
        this.subscription_amount = "$348 per year";
        this.subscription_plan = "Annual";
        break;
     case 'small':
        this.subscription_amount = "$199 per year";
        this.subscription_plan = "Small Firm";
        break;
      case 'basic':
        this.subscription_amount = "$9.99 per month";
        this.subscription_plan = "BASIC";
        break;
      case 'large':
        this.subscription_amount = "$499 per year";
        this.subscription_plan = "Large Firm";
        break;
    }
  }

  cardAdded(card) {

    jQuery(this.cardModalId).modal('hide');

    this.user.card_last_four = card.last4;
    this.user.card_brand = card.brand;

    if (!this.plan) {
      this.toasts.info('Please select plan.');
    } else {
      //TODO need refactor...
      let self = this;
      this.getSubscriptionAmount(this.plan);
      let one_trialline = 7.99;

      const dialogRef = this.modal.confirm()
        .size('lg')
        .isBlocking(true)
        .showClose(true)
        .dialogClass('in')
        .keyboard(27)
        .title('Please, confirm your subscription')
        .body("<p>You will be subscribed to the <b>" + self.subscription_plan + "</b> plan. You will be billed <b>" + self.subscription_amount + "</b>.</p>")
        .open()
      dialogRef.result
      .catch((err: any) => {console.log('ERROR: ' + err)
        this.modelClose()
      })
      .then((dialog: any) => {
        this.modelClose()
        if (dialog) {
          this.createSubscription(this.plan)
        }
        });
      this.modelAction()
    }
  }

  openCardModal() {
    jQuery(this.cardModalId).modal('show');
  }

  cancelSubscription() {

    const dialogRef = this.modal.confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(true)
      .keyboard(27)
      .dialogClass('in')
      .title('Are you sure?')
      .body('<h4>Are you sure you want to cancel User subscription? You can subscribe back at any time.</h4><h5>This will also cancel access for all sub-users subscribed.</h5>')
      .open()
      dialogRef.result
      .catch((err: any) => {console.log('ERROR: ' + err)
        this.modelClose()
      })
      .then((dialog: any) => {
        this.modelClose()
        if(dialog){
          this.confirmedCancelSubscription()
        }
      });
    this.modelAction();
  }

  async cancelForProsperstack(user: any) {
    
    let subscriptionPlan  = (user.subscription_plan == "annual_499_per_year") ? 'Annual': 'Monthly';
    let isFreeTrial = (user.trial_days_count != null) ? 'Yes': 'No';
    
    const result = await flow({
      clientId: "acct_wFRmObH0di1yiOWHNvnrt4Fm",
      subscription: {
        platformId: user.subscriptions[0].stripe_id
      },
      subscriber: {
        properties: {
          preferred_name: user.first_name,
          subscription_plan: subscriptionPlan,
          is_free_trial: isFreeTrial
        },
      },
    });

    return result;
    
  }

  /**
   * Function will cancel the all other active subscriptions of the customer
   * It will run the api and udpate the database entries accordingly
   * Customer will logout after subscriptions auto cancelled
   */
  async fireCancelSubscription() {
    
    this.profileResourceService.cancelProsperstackSubscription().toPromise()
      .then((response: any) => {
        this.user.subscription_plan = null;
        this._changeDetectionRef.detectChanges();
        this.removeCoupon();
        this.toasts.info('You successfully cancelled your subscription');
        this.auth.emit();
        this.user.subscription_plan = null;
        if(response['logout'] == 1) {
          this.auth.logout();
          this.router.navigateByUrl('/');
        }
      })
      .catch((error) => {
        let context = error.error;
        if (context.error.message) {
          this.toasts.error(context.error.message);
        }
      });
      
  }

  async extendTrialDays(ppsResult) {
    console.log('ppsResult', ppsResult)
    const payload = {
      stripe_id: ppsResult.flowSession.subscription.platform_id      
    };

    this.profileResourceService.extendSubscription(payload.stripe_id).toPromise()
      .then((response: any) => {

        console.log('subscription extended response:', response);

      })
      .catch((error) => {
        let context = error.error;
        if (context.error.message) {
          this.toasts.error(context.error.message);
        }
      });
      
  }


  /**
   * Prosperstack Integration
   * Redirect to prosperstack
   * Return with status code canceled, saved & incomplete
   * fireCancelSubscritpoin() function if status is canceled from prosprestack
   */
  async confirmedCancelSubscription() {
    
    const result = await this.cancelForProsperstack(this.user);
    console.log('prosperstack result', result);
    switch (result.status) {
      case "canceled":
        await this.fireCancelSubscription();
      break;
      case "saved":
        console.log('I am in saved box', this.user);
        console.log('prosperstack result', result);
        if(result.flowSession.offer_accepted.details.type == 'trial_extension') {
          await this.extendTrialDays(result);
        }
        if(result.flowSession.offer_accepted.details.type == "pause_subscription") {
          this.toasts.info('choose subscription to continue');
        }
        if(result.flowSession.offer_accepted.type == "custom" && result.flowSession.offer_accepted.metadata.custom_offer_type == "need_support") {
          this.router.navigateByUrl('/support');  // open support component
        }
      break;
      case "incomplete":
        console.log('prosperstack result incomplete', result);
        console.log('I am in incompleted box', this.user)
      break;
    }

  }

  oldtonewsubscription()
  {
    this.oldtonew =true;
    this.oldtonew1 =true;
    this.january22User = true;
  }

  modelAction() {
    $('bs-modal-container').addClass('show-midal-element').addClass('show-midal-element-sub');
  }

  modelClose() {
    $('body').removeClass('modal-open');
  }

  couponDiscount(req: []) {

  }
}
