import {Component,ViewEncapsulation} from '@angular/core';
import { Meta } from '@angular/platform-browser';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-nurse-consultants',
  templateUrl: './nurse-consultants.component.html',
  styleUrls: [
    '../welcome/welcome.component_normalize.css',
      '../welcome/welcome.component_slick.css',
      '../welcome/welcome.component_main.css',
      '../welcome/welcome.component_main_responsive.css',
    './nurse-consultants.component.scss'
  ]
})

export class NurseConsultantsComponent {
  pageSlug: any;
    constructor(
        private meta: Meta, 
       
      ) {
        
        this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    }

}


