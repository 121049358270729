
<section class="banner-section">
    <div class="banner-info">
        <div class="person-img">
            <img src="./assets/images/nurse-consultants/imgs/nurse-img.png" alt="nurse">
        </div>
        <div class="investigator-info">
            <h2 class="heading">
                Legal Nurse Consultants
            </h2>
            <div class="info">
                TrialLine empowers professionals by bridging
                the gap between the legal and medical
                worlds, helping to interpret complex medical
                information and navigate tricky details with
                ease through customizable timelines.
            </div>
            <i class="bottom-info">Maximize Your Case <br> Impact With TrailLine</i>
        </div>
    </div>
    <div class="button-wrapper">
        <a href="/#contact">
            <button>Start Your Free Trial Today</button>
        </a>
    </div>
    <div class="bg-img">
    </div>
</section>

<section class="services-section">
    <div class="container">
        <div class="sevices-content-wrapper">
            <div class="left-info-content">
                <h2>Streamline your workflow, enhance collaboration and present with confidence</h2>
                <div class="blue-line"></div>
                <p>Evaluate Cases, Track Patient Progress, and create customized legal timelines with TrialLine.</p>
            </div>
            <div class="cards-wrapper">
                <div class="card">
                    <img src="./assets/images/nurse-consultants/icons/organise-clock.svg" alt="clock icon">
                    <h3>Organize and Analyze with Ease</h3>
                    <ul>
                        <li>Input and map key medical events in a clear, chronological timeline.
                        </li>
                        <li>Store and access all case documents in one centralized, cloud- based location. </li>
                    </ul>
                </div>
                <div class="card">
                    <img src="./assets/images/nurse-consultants/icons/group-discussion.svg" alt="group discussion">
                    <h3>Collaborate with Colleagues</h3>
                    <ul>
                        <li>Collaborate seamlessly with legal teams and medical experts from anywhere.
                        </li>
                        <li>Access, update and send timelines remotely for continuous case progress and
                            presentation.</li>
                    </ul>
                </div>
                <div class="card engage-and-pursuade">
                    <img src="./assets/images/nurse-consultants/icons/person-with-board.svg" alt="presenting icon">
                    <h3>Engage and Persuade</h3>
                    <ul>
                        <li>Create compelling visual aids to simplify complex medical information.
                        </li>
                        <li>Use dynamic, engaging timelines to tell your story in the courtroom.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="demo-banner">
            <p>Your Go-To Tool for Powerful, Effective Timeline Creation and Presentation.</p>
            <a href="https://calendly.com/triallinedemo/triallinedemo?month=2024-10">
                <button class="book-demo-btn">Book a Demo</button>
            </a>
        </div>
    </div>
</section>
